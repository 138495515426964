import about from "../../assets/images/aboutus/about collage.png";
import arrow from "../../assets/images/aboutus/arrow.jpg";
import side from "../../assets/images/aboutus/side.jpg";
import allimage from "../../assets/images/aboutus/collage.jpg";
import image2 from "../../assets/images/aboutus/about_caurosel5.jpg";
import image from "../../assets/images/aboutus/website photo.jpg";
import image3 from "../../assets/images/aboutus/about_caurosel1.jpg";
import image6 from "../../assets/images/aboutus/about_caurosel2.jpg";
import image5 from "../../assets/images/aboutus/about_caurosel4.jpg";
import image4 from "../../assets/images/aboutus/about_caurosel6.jpg";
import image7 from "../../assets/images/aboutus/about_caurosel7.jpg";
import image8 from "../../assets/images/about_caurosel8.jpg";
import image9 from "../../assets/images/aboutus/about_caurosel9.jpg";
import AutoCarousel from "./AutoCarousel";
import React, { useEffect, useRef } from "react";
import leadership_img6 from "../../assets/images/sherin.png";
import leadership_img8 from "../../assets/images/sudharsan.jpg";
import leadership_img7 from "../../assets/images/Krishnadevi_AGM.jpg";
import leadership_img9 from "../../assets/images/ChinuJohnson.png";
import leadership_img10 from "../../assets/images/Jini.jpg";
import leadership_img11 from "../../assets/images/Abilesh.jpg";
import leadership_img12 from "../../assets/images/AjithMS.jpg";
import leadership_img13 from "../../assets/images/Mahalekshmi.jpg";
import leadership_img14 from "../../assets/images/Shafan_CFO.jpg";
import leadership_img15 from "../../assets/images/GayatriDevi.jpg";
import leadership_img16 from "../../assets/images/Anisha.jpg";

const section1 = [
  {
    content1:
      "Welcome to Eloiacs Softwa Pvt. Ltd We are a leading provider of SaaS dedicated to delivering a wide range of high-quality services to meet the evolving needs of businesses in the digital age.",
    content2:
      "We are at the forefront of technological advancements, committed to empowering businesses with innovative solutions from Nagercoil, Tamil Nadu.",
    aboutimage1: about,
    arrowimage: arrow,
    storyimage1: allimage,
    storyimage2: side,
    story_content1:
      "Eloiacs Softwa Private Limited began its journey on Jan 2021, founded by the visionary Chinu Johnson in Nagercoil. What started as a bold startup has swiftly transformed into a trailblazer in the technology landscape. Driven by a passion for innovation, we set out to redefine what's possible with technology, delivering cutting-edge solutions that blend creativity with functionality.",
    story_content2:
      "Our mission is to push the boundaries of digital excellence. From crafting bespoke web solutions and conducting meticulous software testing to driving impactful digital marketing strategies, we are dedicated to transforming ideas into reality. Our expertise extends beyond traditional IT services to include specialised non-IT solutions like XML, ePub, and PDF accessibility, showcasing our commitment to versatility and comprehensive service.",
    story_content3:
      "As we continue to evolve, we remain steadfast in our pursuit of innovation, striving to set new benchmarks in the industry. Join us on our journey as we forge ahead, shaping the future of technology with every solution we deliver.",
  },
];

const leadershipData = [
  {
    id: 1,
    src: leadership_img16,
    alt: "Leadership 1",
    name: "Anisha",
    position: "Company Secretary",
  },
  {
    id: 2,
    src: leadership_img14,
    alt: "Leadership 2",
    name: "Mohammed Shafan",
    position: "Chief Finance Officer",
  },

  // {
  //   id: 3,
  //   src: "https://images.unsplash.com/photo-1501556466850-7c9fa1fccb4c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTE5fHxidXNpbmVzc3xlbnwwfHwwfHx8MA%3D%3D",
  //   alt: "Leadership 3",
  //   name: "Jane Smith",
  //   position: "CFO",
  // },

  {
    id: 5,
    src: leadership_img10,
    alt: "Leadership 5",
    name: "Jini",
    position: "Quality Analyst",
  },
  {
    id: 6,
    src: leadership_img6,
    alt: "Leadership 6",
    name: "Serin John Weslin",
    position: "Business Development Manager",
  },
  // {
  //   id: 7,
  //   src: "https://plus.unsplash.com/premium_photo-1681491107456-90aa46316186?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHVybHxlbnwwfHwwfHx8MA%3D%3D",
  //   alt: "Leadership 7",
  //   name: "Jane Smith",
  //   position: "CFO",
  // },
  {
    id: 8,
    src: leadership_img7,
    alt: "Leadership 8",
    name: "KrishnaDevi",
    position: "Assistant General Manager",
  },
  {
    id: 9,
    src: leadership_img9,
    alt: "Leadership 9",
    name: "Chinu Johnson",
    position: "Chief Executive Officer",
  },
  {
    id: 9,
    src: leadership_img15,
    alt: "Leadership 9",
    name: "Gayathri Devi R",
    position: "Operations Manager",
  },
  {
    id: 10,
    src: leadership_img8,
    alt: "Leadership 10",
    name: "Sudharsan",
    position: "Technical Lead",
  },

  // {
  //   id: 12,
  //   src: "https://media.istockphoto.com/id/1958337605/photo/portrait-of-a-woman-using-laptop-while-sitting-on-a-mint-couch-at-home.webp?a=1&b=1&s=612x612&w=0&k=20&c=CJx8QimUc8urzemsM3D_xNM57jTtPywresxIUFS4Wjk=",
  //   alt: "Leadership 11",
  //   name: "Jane Smith",
  //   position: "CFO",
  // },
  {
    id: 12,
    src: leadership_img12,
    alt: "Leadership 11",
    name: "Ajith M S",
    position: "Human Resource",
  },
  {
    id: 13,
    src: leadership_img11,
    alt: "Leadership 11",
    name: "Abilesh",
    position: "Project Lead",
  },
  {
    id: 14,
    src: leadership_img13,
    alt: "Leadership 11",
    name: "MahaLekshmi",
    position: "Digital Marketing Specialist",
  },
];

const StatsData = [
  {
    id: 1,
    title: "Innovation",
    bgColor: "bg-blue-300",
    borderColor: "hover:border-blue-300",
    hoverTextColor: "hover:text-blue-300",
  },
  {
    id: 2,
    title: "Flexibility",
    bgColor: "bg-pink-300",
    borderColor: "hover:border-pink-300",
    hoverTextColor: "hover:text-pink-300",
  },
  {
    id: 3,
    title: "Efficiency",
    bgColor: "bg-purple-300",
    borderColor: "hover:border-purple-300",
    hoverTextColor: "hover:text-purple-300",
  },
  {
    id: 4,
    title: "Integrity",
    bgColor: "bg-orange-300",
    borderColor: "hover:border-orange-300",
    hoverTextColor: "hover:text-orange-300",
  },
];

const AboutUs = () => {

  const blockContainerRef = useRef(null);
  const blockSize = 50;

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const numCols = Math.ceil(screenWidth / blockSize);
    const numRows = Math.ceil(screenHeight / blockSize);
    const numBlocks = numCols * numRows;

    function createBlocks() {
      const blockContainer = blockContainerRef.current;
      for (let i = 0; i < numBlocks; i++) {
        const block = document.createElement("div");
        block.classList.add("block");
        block.dataset.index = i;
        block.addEventListener("mousemove", highlightRandomNeighbors);
        blockContainer.appendChild(block);
      }
    }

    function highlightRandomNeighbors(event) {
      const blockContainer = blockContainerRef.current;
      const index = parseInt(event.target.dataset.index);
      const neighbors = [
        index - 1,
        index + 1,
        index - numCols,
        index + numCols,
        index - numCols - 1,
        index - numCols + 1,
        index + numCols - 1,
        index + numCols + 1,
      ].filter(
        (i) =>
          i >= 0 &&
          i < numBlocks &&
          Math.abs((i % numCols) - (index % numCols)) <= 1
      );
      event.target.classList.add("highlight");
      setTimeout(() => {
        event.target.classList.remove("highlight");
      }, 500);

      shuffleArray(neighbors)
        .slice(0, 1)
        .forEach((nIndex) => {
          const neighbor = blockContainer.children[nIndex];
          if (neighbor) {
            neighbor.classList.add("highlight");
            setTimeout(() => {
              neighbor.classList.remove("highlight");
            }, 500);
          }
        });
    }

    function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }

    createBlocks();
  }, []);


  useEffect(() => {
    document.title = "Your Trusted Software company in Nagercoil";
  }, []);

  const carouselImages = [
    [image, image2, image3, image4, image5, image6],
    [image7, image8, image9],
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    
    <div className="blocks-container">
        <div id="blocks" ref={blockContainerRef} />
      </div>

      <div className="mx-auto px-4 py-4  text-white relative">
      {section1.map((item) => (
        <div className="flex flex-col md:flex-row items-center gap-3 mt-10 md:mt-0 px-10">
          <div className="text-left py-12  flex-1 md:py-32 md:px-5">
            <h3 className="text-4xl font-normal mb-2 font-Suse">Hey!</h3>
            <h3 className="font-bold text-3xl 4k:text-5xl mb-10 mt-4 font-Suse">
              We're <span className="text-orange-500">Eloiacs</span> 😊
            </h3>
            <h3 className="text-base text-justify md:text-start md:text-lg 4k:text-2xl font-Inter">
              {item.content1}
            </h3>
            <h4 className="py-5 text-base text-justify md:text-start md:text-lg 4k:text-2xl font-Inter">
              {item.content2}
            </h4>
          </div>
          <div className="image-stack-container flex-1 flex-col md:flex-row mt-32 md:mt-28 ">
            <img
              src={item.aboutimage1}
              alt="Stacked images"
              className="image-stack w-full md:w-3/4 lg:w-2/3 4k:w-1/2"
              loading="eager"
            />
          </div>
        </div>
      ))}

      {section1.map((item) => (
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center  text-justify mt-8 md:mt-10 lg:mt-20 ">
          <div className="px-4 md:px-0 mt-2 flex justify-center items-center lg:flex-col lg:justify-start lg:items-start">
            <img
              src={item.storyimage1}
              alt="Not Found"
              className="w-96 h-full rounded-lg lg:ml-24 mt-52 md:mt-0"
            />
          </div>
          <div className="px-4 md:px-10 lg:px-0 lg:pr-10 mt-10 md:mt-0 ">
            <h3 className="text-3xl 4k:text-4xl font-semibold mb-4 md:mt-10 lg:mt-0 text-left md:text-justify">
              Our{" "}
              <span className="text-orange-500 font-Suse text-[2.25rem]">
                Success
              </span>{" "}
              Evolution
            </h3>

            <p className="tracking-wide font-Inter 4k:text-xl ">
              {item.story_content1}
            </p>
            <p className="mt-4 tracking-wide font-Inter 4k:text-xl">
              {item.story_content2}
            </p>
            <p className="mt-4 tracking-wide font-Inter 4k:text-xl">
              {item.story_content3}
            </p>
          </div>
        </div>
      ))}

      {section1.map((item) => (
        <div className="inline-block mt-5">
          <img
            src={item.storyimage2}
            alt="Not Found"
            className="w-52 h- rounded-lg md:ml-60 ml-10 mt-4 md:mt-0 mb-5 md:mb-5"
          />
        </div>
      ))}

      <div className="px-4 md:px-0 md:p-10 lg:p-16 ">
        <div className="mb-10 ">
          <p className="font-semibold text-2xl lg:text-3xl 4k:text-4xl text-center">
            Our <span className="text-orange-500">Leaderships</span>
          </p>
        </div>
        <div className="columns-2 gap-y-5 lg:gap-8 md:columns-2 lg:columns-4 xl:columns-5  space-y-5 ">
          {leadershipData.map((leader) => (
            <div
              key={leader.id}
              className="relative group overflow-hidden rounded-lg "
            >
              <img
                src={leader.src}
                alt={leader.alt}
                className="transition-all duration-300 ease-in-out transform group-hover:blur-sm"
              />

              <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <p className="text-white font-bold text-lg 4k:text-2xl tracking-wide font-Montserrat text-center">
                  {leader.name}
                </p>
                <p className="text-white text-sm 4k:text-xl font-Inter tracking-wide text-center">
                  {leader.position}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="px-4 md:px-0 w-full max-w-4xl mx-auto md:mt-2">
        <div className="text-center mb-8  md:px-10 lg:px-0 ">
          <h1 className="text-xl md:text-2xl lg:text-3xl 4k:text-4xl font-bold mb-10 mt-5 md:mt-0">
            We are an <i className="font-normal text-orange-500">Open</i>{" "}
            Company
          </h1>
          <p className="mt-4 text-base md:text-lg 4k:text-2xl text-white text-justify md:text-center text-opacity-40 font-PTSansCaption">
            At Eloiacs Softwa Private Limited, we champion a culture of
            openness, where transparency, dynamic communication, and
            collaborative innovation drive our success. We ensure that our
            processes and decisions are transparent, fostering an environment
            where creativity flourishes and diverse ideas converge. By
            encouraging an ecosystem of idea-sharing and feedback, we propel
            groundbreaking solutions and tackle challenges with agility. Our
            commitment to being accessible and inclusive helps us build robust,
            trust-based relationships and craft pioneering solutions that
            reflect collective ingenuity and forward-thinking expertise.
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 md:px-8 lg:px-0 gap-2 md:gap-4 lg:gap-6 4k:gap-10">
          {StatsData.map((item) => (
            <div
              key={item.id}
              className={`${item.bgColor} flex flex-col justify-center items-center p-6 rounded-lg text-center py-8 md:py-16 lg:py-20`}
            >
              <p className="mt-2 text-sm md:text-lg lg:text-xl 4k:text-2xl text-center uppercase font-semibold font-Suse">
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="p-5 md:p-20 pb-6 md:pb-20 lg:pb-20">
        <p className="text-white text-base md:text-lg lg:text-2xl 4k:text-4xl text-center font-Inter font-light">
          <span className="text-orange-500 font-semibold">Your vision</span>,
          our code – together, we innovate.
        </p>
      </div>
      <div className="flex justify-between items-center  md:mt-10 mt-5">
        <AutoCarousel images={carouselImages} />
      </div>
    </div>

    </>

  );
};

export default AboutUs;
