import AnimatedText from "../../components/AnimatedText";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div className="relative pointer-events-none mb-10 md:mb-20 lg:mb-36">
      <div className="md:ms-10 lg:ms-0 text-stroke-orange text-transparent">
        <AnimatedText>About us</AnimatedText>
      </div>
      <motion.div
        className="text-center text-white font-Play text-lg sm:text-3xl md:text-4xl lg:text-5xl 4k:text-7xl  font-black mt-7 4k:mt-16 px-8 md:px-20 lg:px-16"
        whileInView={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3, delay: 1 }}
        viewport={{ once: true }}
      >
        Driven by technology, guided by vision—delivering excellence every time
      </motion.div>
   

      <motion.div
        className="grid grid-cols-1 md:grid-cols-3 text-white mt-5 md:mt-16 px-14"
        whileInView={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3, delay: 1 }}
        viewport={{ once: true }}
      >
        <div className="text-center">
          <p className="text-xl sm:text-3xl md:text-4xl lg:text-5xl 4k:text-7xl font-black font-mono">
            10<sup>+</sup>
          </p>
          <span className="text-xs md:text-sm 4k:text-xl text-gray-400 font-medium font-mono">
            countries where we have
            <br />
            trusting clients
          </span>
        </div>
        <div className="text-center mt-4 md:mt-0">
          <p className="text-xl sm:text-3xl md:text-4xl lg:text-5xl 4k:text-7xl font-black font-mono">
            200<sup>+</sup>
          </p>
          <span className="text-xs md:text-sm 4k:text-xl text-gray-400 font-medium font-mono">
            Satisfied Clients
          </span>
        </div>
        <div className="text-center mt-4 md:mt-0">
          <p className="text-xl sm:text-3xl md:text-4xl lg:text-5xl 4k:text-7xl font-black font-mono">
            700<sup>+</sup>
          </p>
          <span className="text-xs md:text-sm 4k:text-xl text-gray-400 font-medium font-mono">
            Training(days) conducted for
            <br />
            employees, globally
          </span>
        </div>
      </motion.div>

    </div>
  );
};

export default About;