import React, { useState, useEffect } from "react";
import PROFILE1 from "../../assets/images/goldenstarpharmacy_logo.png";
import PROFILE2 from "../../assets/images/Luxore Delights - Logo.png";
import PROFILE3 from "../../assets/images/Rishik Engineering Logo.png";
import PROFILE4 from "../../assets/images/H&T_Holidays_Logo.png";
import PROFILE5 from "../../assets/images/Nurses On Board - Logo.png";

const testimonialsData = [
  {
    id: 1,
    name: "Kannan",
    role: "CEO",
    companyName: "Golden Star Pharmacy",
    image: PROFILE1,
    color: "bg-black",
    textColor: "text-white",
    text: "I was struggling with my business until I selected Eloiacs for developing our website and handling our marketing. Their team helped us identify areas for improvement and craft a strategic plan, which has led to significant growth in revenue. Their expertise in both web development and marketing has been instrumental in taking our company to the next level. I highly recommend their services to any business owner looking to enhance their online presence and achieve substantial growth.",
    star: 5,
  },
  {
    id: 2,
    name: "Haroon",
    role: "Manager",
    companyName: "H&T Holidays Tours and Travel",
    image: PROFILE4,
    color: "bg-black",
    textColor: "text-white",
    text: "We hired Eloiacs to develop our accounts management application, and it was perfect for our business. Their team's expertise delivered exactly what we needed to streamline our processes and improve efficiency. Highly recommend their services!",
    star: 5,
  },
  {
    id: 3,
    name: "Rishik",
    role: "Owner",
    companyName: "Rishik Engineering",
    image: PROFILE3,
    color: "bg-black",
    textColor: "text-white",
    text: "I wanted a minimalistic website to showcase my product, and the Eloiacs team delivered exactly that. They helped me achieve a clean, effective design that highlights my product perfectly. Huge kudos to the team!",
    star: 5,
  },
  {
    id: 4,
    name: "Jas",
    role: "CEO",
    companyName: "LUXORÉ DELIGHTS",
    image: PROFILE2,
    color: "bg-black",
    textColor: "text-white",
    text: "I wanted to sell my products online and also establish a strong digital presence, so I approached the Eloiacs team for both e-commerce development and digital marketing. They delivered a fantastic e-commerce platform and helped build an effective digital marketing strategy. Their expertise was crucial in achieving my goals.",
    star: 5,
  },
  {
    id: 5,
    name: "John",
    role: "",
    companyName: "Nurses on board",
    image: PROFILE5,
    color: "bg-black",
    textColor: "text-white",
    text: "I wanted to create an e-learning platform for teaching courses to global clients, so I approached the Eloiacs team. They executed the project perfectly, delivering a seamless and effective solution.",
    star: 5,
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
      );
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="flex relative bg-white py-9 md:py-14 justify-center items-center ">
        <p className="absolute text-3xl md:text-5xl font-bold font-Suse">
          Our Testimonials
        </p>
      </div>
      <div className="relative bg-white md:pt-4  lg:pt-10 pb-10 md:pb-20 min-h-96 px-6 lg:px-12  shadow-md  w-full grid md:grid-cols-3 gap-2 lg:gap-4 ">
        <div className="col-span-1 md:px-8 text-start  items-center ">
          <h2 className="text-2xl md:text-4xl font-bold mb-4 font-Inter text-center md:text-left">
            What our{" "}
            <span className="hidden md:inline">
              <br />
            </span>{" "}
            customers think
          </h2>

          <p className="text-base md:text-lg 4k:text-2xl mb-4 font-Inter text-justify ">
            Our customers trust us for delivering digital solutions that drive
            success. They appreciate our expertise, responsiveness, and
            dedication to meeting their unique needs, making us their go-to
            technology partner
          </p>
          <div className="flex gap-2">
            {testimonialsData.map((_, index) => (
              <hr
                key={index}
                className={`border-4 ${
                  index === currentIndex
                    ? " border-y-yellow-700"
                    : "border-gray-300"
                } w-12`}
              />
            ))}
          </div>
        </div>
        <div className="col-span-2 px-3 md:px-8 relative ">
          <TestimonialsView testimonial={testimonialsData[currentIndex]} />
        </div>
      </div>
    </div>
  );
};

const TestimonialsView = ({ testimonial }) => {
  return (
    <div
      className={`mt-5 md:mt-0 p-4 md:p-8 ${testimonial.color} ${testimonial.textColor} md:h-[27rem] lg:h-96`}
    >
      <div className="flex flex-col items-center text-center">
        <img
          src={testimonial.image}
          alt={testimonial.name}
          className="w-20 h-20 mb-4"
        />
        <h3 className="text-xl font-semibold font-Play 4k:text-3xl uppercase">
          {testimonial.name}
        </h3>

        <div className="flex">
          {[...Array(Math.floor(testimonial.star))].map((_, i) => (
            <svg
              key={i}
              className="w-6 h-6 text-yellow-500"
              fill="currentColor"
              viewBox="0 0 30 20"
            >
              <path d="M10 15l-5.878 3.09L5.309 10 .5 6.455 7.122 5.09 10 .5l2.878 4.59 6.622 1.365L14.691 10 15.878 18z" />
            </svg>
          ))}
          {testimonial.star % 1 !== 0 && (
            <svg
              className="w-6 h-6 text-yellow-500"
              fill="currentColor"
              viewBox="0 0 30 20"
            >
              <path
                d="M10 15l-5.878 3.09L5.309 10 .5 6.455 7.122 5.09 10 .5l2.878 4.59 6.622 1.365L14.691 10 15.878 18z"
                fillOpacity="0.5"
              />
            </svg>
          )}
        </div>
        <div title={testimonial.text} className="pt-8">
          <p
            className={`${testimonial.textColor} mb-4 text-md 4k:text-xl text-justify font-Inter`}
          >
            {testimonial.text.split(" ").length > 30
              ? testimonial.text.split(" ").slice(0, 30).join(" ") +
                "...." +
                " " +
                testimonial.text.split(" ").slice(-1)
              : testimonial.text}
          </p>
        </div>
      </div>
      <p className="text-sm text-gray-600 mb-2 text-start flex justify-start font-Gabriela">
        {testimonial.role} <br /> {testimonial.companyName}
      </p>
    </div>
  );
};

export default Testimonial;
