import React, { useRef } from "react";

const AutoCarousel = ({ images }) => {
  const carouselRef = useRef(null);

  const handleMouseEnter = () => {
    if (carouselRef.current) {
      carouselRef.current.classList.add("paused");
    }
  };

  const handleMouseLeave = () => {
    if (carouselRef.current) {
      carouselRef.current.classList.remove("paused");
    }
  };

  const totalSets = images.length;
  const totalWidth = `${totalSets * 70}%`;
  const itemWidth = `${70 / totalSets}%`;

  return (
    <div
      ref={carouselRef}
      className="carousel-container "
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="carousel-track"
        style={{
          width: totalWidth,
        }}
      >
        {images.map((imageSet, index) => (
          <div key={index} className="flex-shrink-0 flex w-[100rem] h-56 md:h-full md:w-full ">
            {imageSet.map((img, subIndex) => (
              <div key={subIndex} className="p-2 " style={{ width: itemWidth }}>
                <img
                  src={img}
                  alt={`Image ${index * 4 + subIndex + 1}`}
                  className="w-[80rem] md:w-full h-auto rounded-lg shadow-lg "
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutoCarousel;
