import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { AnimatedButton } from "./AnimatedButton";
import LOGO from "../assets/images/logo2.png";
import { useScroll } from "../context/ScrollContext";

const navLinks = [
  { id: 1, name: "Home", href: "/" },
  { id: 2, name: "Services", href: "/services" },
  { id: 3, name: "About", href: "/about-us" },
  { id: 4, name: "Career", href: "/career" },
  { id: 5, name: "Blog", href: "https://wordpress.com/" },
  { id: 6, name: "Contact", href: "/contact" },
];

const Navbar = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [buttonActive, setButtonActive] = useState(false); // State for AnimatedButton
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  const { setTargetSection } = useScroll();

  useEffect(() => {
    const currentPath = location.pathname;
    const activeLink = navLinks.find((link) => link.href === currentPath);
    if (activeLink) {
      setActiveTab(activeLink.id);
    }
  }, [location.pathname]);

  // const submenuVariants = {
  //   open: { height: "100vh", opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } },
  //   close: { height: 0, opacity: 0, transition: { duration: 0.3, ease: "easeInOut" } },
  // };

  const submenuVariants = {
    open: {
      height: "100vh",
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
        staggerChildren: 0.1, // Stagger animation for children
        delayChildren: 0.1,
      },
    },
    close: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
        staggerChildren: 0.1,
        staggerDirection: -1, // Reverse stagger when closing
      },
    },
  };

  const menuItemVariants = {
    hidden: {
      opacity: 0,
      x: -100, // Start off-screen to the left
    },
    visible: {
      opacity: 1,
      x: 0, // Move to the correct position
      transition: {
        type: "spring",
        stiffness: 120,
        damping: 20,
      },
    },
  };

  const handleNavigation = (href) => {
    if (href.startsWith("http")) {
      window.open(href, "_blank"); // Open external site in a new tab
    } else if (href.startsWith("#")) {
      const element = document.querySelector(href);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else if (href.startsWith("/")) {
      if (location.pathname === href) {
        // If the current path is the same as the clicked link, scroll to top
        window.scrollTo(0, 0);
      } else {
        navigate(href);
      }
    } else {
      setTargetSection(href);
      navigate("/");
    }

    // Close the menu after navigation
    setMenuOpen(false);
    setButtonActive(false); // Reset the AnimatedButton to inactive state
  };

  return (
    <header className="fixed md:px-4 md:py-1.5 lg:px-8 lg:py-3 4k:py-8 z-50 w-full lg:bg-opacity-30 bg-black backdrop-blur-sm top-0 left-0 ">
      <nav className="flex justify-between items-center ">
        <RouterLink to="/" onClick={() => window.scrollTo(0, 0)}>
          {/* Scroll to top on click */}
          <img src={LOGO} width={100} alt="Eloiacs-Logo" />
        </RouterLink>
        <div className="flex space-x-4 max-lg:hidden ">
          {navLinks.map((item) => (
            <button
              key={item.id}
              onClick={() => {
                setActiveTab(item.id);
                handleNavigation(item.href);
              }}
              className={`${
                activeTab === item.id ? "" : "hover:text-white/60"
              } relative rounded-full px-3 py-1.5 uppercase text-sm text-white font-semibold outline-sky-400 transition focus-visible:outline-2 tracking-wider 4k:text-xl`}
              style={{ WebkitTapHighlightColor: "transparent" }}
            >
              {activeTab === item.id && (
                <motion.span
                  layoutId="bubble"
                  className="absolute inset-0 z-10 bg-white mix-blend-difference"
                  style={{ borderRadius: 9999 }}
                  transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                />
              )}
              {item.name}
            </button>
          ))}
        </div>
        <div
          className="hidden max-lg:block "
          onClick={() => {
            setMenuOpen(!menuOpen);
            setButtonActive(!buttonActive); // Toggle the active state of the AnimatedButton
          }}
        >
          <AnimatedButton active={buttonActive} setActive={setButtonActive} />
        </div>
      </nav>
      <AnimatePresence>
        {menuOpen && (
          <motion.ul
            className="text-white h-screen ps-8"
            initial="close"
            animate="open"
            exit="close"
            variants={submenuVariants}
          >
            {navLinks.map((item) => (
              <motion.li
                key={item.id}
                className="text-2xl text-white mix-blend-difference px-4 pt-4 pb-2"
                variants={menuItemVariants} // Apply menu item animation
                initial="hidden"
                animate="visible"
              >
                <button
                  onClick={() => {
                    handleNavigation(item.href);
                    setActiveTab(item.id); // Ensure activeTab is updated
                  }}
                  // Conditionally set the text color to white if the item is active
                  className={`text-xl tracking-wide font-semibold ${
                    activeTab === item.id
                      ? "text-white font-DMsans"
                      : "text-orange-600"
                  }`}
                >
                  {item.name}
                </button>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Navbar;
