import React from "react";

const Loader = () => {
  return (
    <div
      className="flex justify-center items-center fixed inset-0 bg-gray-400 bg-opacity-70 backdrop-blur-md"
      style={{ zIndex: 60 }}
    >
      <div className="relative w-8 h-8 border-4 border-t-4 border-t-orange-500 border-gray-200 rounded-full animate-spin"></div>
    </div>
  );
};

export default Loader;
