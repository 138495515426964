import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Services from "./pages/services/Services";
import Hero from "./pages/hero/Hero1";
import Testimonial from "./pages/testimonial/Testimonial";
import Career from "./pages/career/Career";
import Description from "./pages/career/Description";
import { ScrollProvider, useScroll } from "./context/ScrollContext";
import Lenis from "lenis";
// import Footer from "./components/Footer";
import About from "./pages/about-us/About";
import ScrollTop from "./components/ScrollTop";
import AboutUs from "./pages/about-us/AboutUS";
import ServiceDetails from "./pages/services/ServiceDetails";
import ServiceDetails1 from "./pages/services/ServicesDetails1";
import ServicesPage from "./pages/services/ServicesPage";
import WhatsApp from "./components/WhatsApp";
import CareerSection from "./pages/career/CareerSection";
import Loader from "./components/Loader";
import ContactPage from "./pages/contact/ContactPage";
import FooterNew from "./components/FooterNew";

const MainPage = () => {
  useEffect(() => {
    document.title = "ELOIACS - BEST SOFTWARE COMPANY IN NAGERCOIL";
  }, []);

  const { targetSection, setTargetSection } = useScroll();
  const location = useLocation();

  useEffect(() => {
    if (targetSection) {
      const element = document.getElementById(targetSection);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setTargetSection(null);
      }
    }
  }, [targetSection, setTargetSection, location]);

  return (
    <>
      <section id="home">
        <Hero />
      </section>
      <section>
        <WhatsApp />
      </section>
      <section id="services" className="pt-0 md:pt-12">
        <Services />
      </section>
      <section className="pt-12">
        <About />
      </section>
      <section>
        <Testimonial />
      </section>
      <section>
        <CareerSection />
      </section>
    </>
  );
};

const AppContent = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      <Navbar />
      <ScrollTop />
      <Routes>
        <Route path="/" exact element={<MainPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/ourservices" element={<ServiceDetails />} />
        <Route path="/career" exact element={<Career />} />
        <Route path="/description/:id" exact element={<Description />} />
        <Route path="/:name" element={<ServiceDetails1 />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      {/* <Footer /> */}
      <FooterNew />
    </>
  );
};

// Main App Component with Router and ScrollProvider
function App() {
  useEffect(() => {
    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
  }, []);

  return (
    <ScrollProvider>
      <Router>
        <AppContent />
      </Router>
    </ScrollProvider>
  );
}

export default App;
