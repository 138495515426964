import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Facebook, Instagram, Youtube, LinkedIn } from "../assets/icons";
import emailjs from "emailjs-com";
import NotifyButton from "./NotifyButton";

const footerData = [
  {
    title: "Quick Links",
    links: [
      { name: "Home", path: "/" },
      { name: "About Us", path: "/about-us" },
      { name: "Career", path: "/career" },
    ],
  },
  {
    title: "Services",
    links: [
      { name: "Software Development", path: "/software-development" },
      {
        name: "Mobile App Development",
        path: "/mobile-app-development-services",
      },
      { name: "Digital Marketing", path: "/digital-marketing-services" },
      { name: "PDF Accessibility", path: "/pdf-accessibility" },
      { name: "E-Books Conversion", path: "/ebook-conversion" },
      { name: "Data Entry", path: "/data-entry" },
    ],
  },
  {
    title: "Support Services",
    links: [
      { name: "Web Accessibility", path: "/web-accessibility" },
      { name: "Word Accessibility", path: "/word-accessibility" },
      { name: "Medical Coding", path: "/medical-coding" },
      { name: "E-Publishing", path: "/e-publishing" },
      { name: "XML Conversion", path: "/xml-conversion" },
      { name: "Type Setting", path: "/type-setting" },
      { name: "Copy Editing", path: "/copy-editing" },
    ],
  },
];

const socialIcons = [
  { src: Facebook, alt: "Facebook", link: "https://www.facebook.com/eloiacs" },
  {
    src: Instagram,
    alt: "Instagram",
    link: "https://www.instagram.com/eloiacs_softwa_pvt.ltd/",
  },
  {
    src: Youtube,
    alt: "Youtube",
    link: "https://www.youtube.com/@eloiacs_softwa",
  },
  {
    src: LinkedIn,
    alt: "LinkedIn",
    link: "https://www.linkedin.com/company/99473854/admin/dashboard/",
  },
];

const FooterNew = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const formRef = useRef();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.length === 0) {
      setError(true);
    } else {
      setError(false);
      sendEmail();
      setSuccess(true);
    }
  };

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_ot0wd39",
        "template_tddvger",
        formRef.current,
        "e0RTXjhasJQ16WqE7"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setEmail("");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-y-10 md:gap-y-0 lg:px-20 text-white py-6 bg-black relative">
        {footerData.map((section, index) => (
          <div key={index} className="col-span-1 space-y-2 px-5 lg:px-0">
            <p className="text-lg font-semibold text-19px text-orange-500 underline decoration-orange-500">
              {section.title}
            </p>
            <div className="text-15px -space-y-2 text-sm 4k:text-lg tracking-wide">
              {section.links.map((link, linkIndex) => (
                <div key={linkIndex} className="relative group">
                <Link
                  to={link.path}
                  className={`block text-nowrap pb-1 transition-all duration-300 relative
                    ${
                      location.pathname === link.path
                        ? "text-orange-500 font-semibold"
                        : "text-white"
                    } group-hover:text-orange-500 group-hover:font-semibold group-hover:scale-110`}
                >
                  {link.name}
                </Link>
              </div>
              ))}
            </div>
          </div>
        ))}

        <div className="order-first lg:order-last col-span-1 md:col-span-3 lg:col-span-2 flex flex-col items-center py-4 px-0 md:px-16 lg:px-0">
          <div className="text-orange-500 p-8 md:p-12 w-full bg-opacity-90 border-0 border-t-2 border-b-2 md:border-white rounded-3xl shadow-lg shadow-stone-0 md:shadow-stone-50">
            <p className="text-xl text-center pb-4 uppercase font-bold">
              Join our newsletter
            </p>
            <form action="" className="" ref={formRef} onSubmit={handleSubmit}>
              <div className="flex space-x-2">
                <input
                  className={`border-2 border-solid border-b-white text-white py-2 px-3 h-8 w-full text-sm md:text-base outline-none bg-transparent ${
                    error && !email ? "border-b-red-500" : "border-black"
                  }`}
                  type="text"
                  placeholder="Enter your Email Address"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <NotifyButton />
              </div>
              {error && !email && (
                <p className="text-red-500 text-sm italic mt-2">
                  Email is required.
                </p>
              )}
            </form>
            {success && (
              <p className="text-green-500 text-sm italic mt-2">
                Thank you! You've successfully subscribed.
              </p>
            )}
          </div>
          <div className="flex justify-center items-center pt-10 space-x-6">
            {socialIcons.map((icon, iconIndex) => (
              <a
                key={iconIndex}
                href={icon.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={icon.src} className="w-8 h-8" alt={icon.alt} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterNew;
