import { useTransform, useScroll, motion } from "framer-motion";
import WEB from "../../assets/images/website development.jpg";
import ACCESSIBILITY from "../../assets/images/accessibility.webp";
import DIGITAL from "../../assets/images/digital-marketing.webp";
import AI from "../../assets/images/articifical intelligence.webp";
import MOBILE from "../../assets/images/mobile-app-development_1.webp";
import { useRef } from "react";
import AnimatedText from "../../components/AnimatedText";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  const navigate = useNavigate();

  const handleLearnMoreClick = (link) => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <div ref={container} className="mt-0  lg:mt-0">
      {/* <div className="lg:ms-16 4k:ms-24 text-transparent text-stroke-orange"> */}
      <div className="md:ms-10 lg:ms-0 text-transparent text-stroke-orange">
        <AnimatedText children="OUR SERVICES" />
      </div>

      {projects.map((project, i) => {
        const targetScale = 1 - (projects.length - i) * 0.05;
        return (
          <Card
            key={`p_${i}`}
            i={i}
            {...project}
            progress={scrollYProgress}
            range={[i * 0.25, 1]}
            targetScale={targetScale}
            onClick={() => handleLearnMoreClick(project.links)}
          />
        );
      })}
    </div>
  );
};

export default Services;

const projects = [
  {
    title: "WEBSITE DEVELOPMENT",
    description:
      "Experience the transformative power of a compelling website that breathes life into your brand. Embrace the digital age with top-tier website development services designed to elevate your online presence.",
    src: WEB,
    color: "#FFFFFF",
    links: "/website-development-services",
  },
  {
    title: "MOBILE APP DEVELOPMENT",
    description:
      "Stay connected with your customers on the go with our mobile app development services, designed to enhance user engagement and satisfaction.",
    src: MOBILE,
    color: "#977F6D",
    links: "/mobile-app-development-services",
  },
  {
    title: "ARTIFICIAL INTELLIGENCE",
    description:
      "Leverage the power of AI to transform your business with our cutting-edge solutions. Drive efficiency, enhance user experiences, and gain valuable insights with our AI-driven services.",
    src: AI,
    color: "#F8821E",
    links: "/artificial-intelligence-solutions",
  },
  {
    title: "DIGITAL MARKETING",
    description:
      "Let our digital marketing services guide you through the vast world of commerce, leading you to unparalleled success. Embrace the future with the insights of the past, and watch your brand soar to new heights.",
    src: DIGITAL,
    color: "#BBACAF",
    links: "/digital-marketing-services",
  },
  {
    title: "ACCESSIBILITY",
    description:
      "Ensure your documents are accessible to everyone, including those with disabilities, with our PDF Accessibility service. Transform your PDFs to meet international standards and improve user experience for all.",
    src: ACCESSIBILITY,
    color: "#977F6D",
    links: "/pdf-accessibility",
  },
];

const Card = ({
  i,
  title,
  description,
  src,
  color,
  progress,
  range,
  targetScale,
  onClick,
}) => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "start start"],
  });

  const imageScale = useTransform(scrollYProgress, [0, 1], [2, 1]);
  const scale = useTransform(progress, range, [1, targetScale]);

  return (
    <div
      ref={container}
      className="sticky h-screen flex items-center justify-center top-0 z-10 px-8 md:px-0 "
    >
      <motion.div
        className="relative flex flex-col h-96 4k:h-[32rem] w-11/12 md:w-4/5 4k:w-5/6 rounded-2xl p-4 md:p-10 origin-top "
        style={{
          backgroundColor: color,
          scale,
          top: `calc(-5vh + ${i * 25}px)`,
        }}
      >
        <h2
          className="text-center text-base md:text-xl 4k:text-4xl m-0 font-Suse font-semibold hover:underline hover:underline-offset-2 cursor-pointer  "
          onClick={onClick}
        >
          {title}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:text-justify h-full md:mt-8 gap-6 md:gap-12 mt-1.5 ">
          <div className="relative lg:top-[10%] 4k:top-0  4k:ps-20  4k:flex 4k:justify-center 4k:items-center">
            <p className="font-medium text-sm md:text-lg 4k:text-3xl first-letter:text-[28px] tracking-wide font-Inter 4k:tracking-wider">
              {description}
            </p>
          </div>
          <div className="relative h-full flex rounded-3xl overflow-hidden -mt-3 md:mt-0 4k:flex justify-evenly items-center ">
            <motion.div style={{ scale: imageScale }}>
              <img
                src={src}
                alt="Services"
                className="object-cover w-full md:w-96 h-32 md:h-52 rounded-3xl"
                loading="eager"
              />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
