import { motion } from "framer-motion";
import React from "react";

const DURATION = 0.25;
const STAGGER = 0.025;

const FlipText = ({ children }) => {
  const childrenArray = React.Children.toArray(children).map((child) =>
    typeof child === "string" ? child.split("") : child
  );

  return (
    <motion.div
      initial="initial"
      whileHover="hovered"
      className="relative cursor-pointer overflow-hidden whitespace-nowrap text-white text-6xl font-black uppercase sm:text-6xl md:text-6xl lg:text-8xl xl:text-9xl 4k:text-12xl"
    >
      <div>
        {childrenArray.map((child, i) =>
          typeof child === "string" ? (
            child.map((l, j) => (
              <motion.span
                variants={{
                  initial: {
                    y: 0,
                  },
                  hovered: {
                    y: "-100%",
                  },
                }}
                transition={{
                  duration: DURATION,
                  ease: "easeInOut",
                  delay: STAGGER * j,
                }}
                className="inline-block"
                key={`${i}-${j}`}
              >
                {l}
              </motion.span>
            ))
          ) : (
            <motion.span
              variants={{
                initial: {
                  y: 0,
                },
                hovered: {
                  y: "-100%",
                },
              }}
              transition={{
                duration: DURATION,
                ease: "easeInOut",
                delay: STAGGER * i,
              }}
              className="inline-block"
              key={i}
            >
              {child}
            </motion.span>
          )
        )}
      </div>
      <div className="absolute inset-0">
        {childrenArray.map((child, i) =>
          typeof child === "string" ? (
            child.map((l, j) => (
              <motion.span
                variants={{
                  initial: {
                    y: "100%",
                  },
                  hovered: {
                    y: 0,
                  },
                }}
                transition={{
                  duration: DURATION,
                  ease: "easeInOut",
                  delay: STAGGER * j,
                }}
                className="inline-block"
                key={`${i}-${j}`}
              >
                {l}
              </motion.span>
            ))
          ) : (
            <motion.span
              variants={{
                initial: {
                  y: "100%",
                },
                hovered: {
                  y: 0,
                },
              }}
              transition={{
                duration: DURATION,
                ease: "easeInOut",
                delay: STAGGER * i,
              }}
              className="inline-block"
              key={i}
            >
              {child}
            </motion.span>
          )
        )}
      </div>
    </motion.div>
  );
};

export default FlipText;
